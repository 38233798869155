import React, { Fragment } from "react"
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col } from 'react-bootstrap';
import "./Layout.css";
import Siderbar from "./Sidebar";
import logo from '../components/balldream-logo.png';

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            //padding: 10
            marginBottom: 0,
          }}
        >
          <Link className="navbar-brand" to="/">
            <img alt="balldream.com" src={logo} />
            <p>บ้านผลบอล วิเคราะห์บอล</p>
          </Link>
        </h1>
      )
    } else {
      header = (
        <span
          style={{
            padding: 10
          }}
        >
          <Link className="navbar-brand" to="/">
            <img alt="Wingman" src={logo} />
          </Link>
        </span>
      )
    }
    return (
      <Fragment>
        <Header tagName={header} />
        <section className="space-sm flush-with-above">
          <div className="container">

            {children}
            {/* <Siderbar /> */}

          </div>
        </section>

        <Footer />
      </Fragment>
    )
  }
}

export default Layout
