import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"

class Header extends React.Component {
  render() {
    return (
      <div className="navbar-container">
        <div className="bg-dark navbar-dark position-fixed">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              {this.props.tagName}
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img" focusable="false"><title>Menu</title><path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M4 7h22M4 15h22M4 23h22"></path></svg>
              </button>
              <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/บอลตางประเทศ" className="nav-link">บอลต่างประเทศ</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/ฟตบอลไทย" className="nav-link">ฟุตบอลไทย</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/วเคราะหบอล" className="nav-link">วิเคราะห์บอล</Link>
                  </li>
                </ul>
              </div>

            </nav>
          </div>

        </div>
      </div>
    )
  }
}

export default Header;