import React from 'react';

class Siderbar extends React.Component {

    render() {
        return (
            <>
                <div className="col-md-3">
                    <div className="sidebar sidebar-sticky">
                        <h3 className="title-widget">Danh Mục</h3>
                        <ul>
                            <li>Dmeo</li>
                            <li>Demo 2 3 4</li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default Siderbar;