import React from 'react';
import { Link } from "gatsby"
import logo from '../components/balldream-logo.png';
class Footer extends React.Component {

  render() {
    return (
      <footer className="bg-gray text-light footer-long">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <img alt="Image" src={logo} className="mb-4" style={{ maxWidth: 200 }} />
              <p className="text-muted">
                ติดตามการวิเคราะห์บอลที่เราจัดให้ทุกคู่ ทุกการแข่งขัน แม่นยำแทบจะ 100% ไม่ต้องไปที่ไหน ที่นี่เราจัดเต็ม สถิติๆต่างที่สำคัญมากมาย สถิติย้อนหลัง ฟอร์มการเล่นย้อนหลัง คาดการณ์ตัวผู้เล่นทีมเหย้า ทีมเยือน นักเตะเจ็บ นักเตะโดนแบน ผู้จัดการทีม สนามแข่ง เรตราคาบอล เราเก็บข้อมูลจากทุกสิ่งทุกอย่างที่มีผลกระทบต่อการแข่งขันแบบเจาะลึก สำหรับแฟนๆเชียร์บอลทุกท่าน เรื่องกีฬาฟุตบอลมั่นใจเราได้เลย ด้วยเซียนชั้นนำของประเทศ ฟันธงผลบอล ทรรศนะบอล ข้อมูลฟุตบอลแบบละเอียดยิบลึกซึ้งเข้าถึงกึ๋น อยู่ในหน้าเว็บนี้แล้ว หากคุณคือคอบอลตัวจริง... ไม่ควรพลาดทุกประการ
              </p>
            </div>

            <div className="col-12 col-md-8">
              <p>บ้านผลบอล, ไลฟ์สกอร์, ทีเด็ดบอลวันนี้, วิเคราะห์บอล</p>
              <div className="row no-gutters">


                <div className="col-8 col-lg-4">
                <p>ข่าวฟุตบอล</p>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/พรเมยรลก">พรีเมียร์ลีก</Link>
                    </li>
                    <li>
                      <Link to="/ลกเอง">ลีกเอิง</Link>
                    </li>
                    <li>
                      <Link to="/บนเดสลกา">	บุนเดสลีกา</Link>
                    </li>
                    <li>
                      <Link to="/เซเรย-อา">เซเรีย อา</Link>
                    </li>
                    <li>
                      <Link to="/ลาลกา">ลาลีกา</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-8 col-lg-4">
                  <p>ตารางคะแนน</p>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/ตารางคะแนน-พรเมยรลก">ตารางคะแนน พรีเมียร์ลีก</Link>
                    </li>
                    <li>
                      <Link to="/ตารางคะแนน-ลาลกา">ตารางคะแนน ลาลีกา</Link>
                    </li>
                    <li>
                      <Link to="/ตารางคะแนน-เซเรย-อา">ตารางคะแนน เซเรีย อา</Link>
                    </li>
                    <li>
                      <Link to="/ตารางคะแนน-บนเดสลกา">ตารางคะแนน บุนเดสลีกา</Link>
                    </li>
                    <li>
                      <Link to="/ตารางคะแนน-ลกเอง">ตารางคะแนน ลีกเอิง</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-8 col-lg-4">
                  <p>ติดต่อเรา</p>
                  <ul className="list-unstyled">
                    <li>
                    <a href="https://www.linkedin.com/company/balldream">Linkedin</a>
                    </li>
                    <li>
                      <a href="https://twitter.com/_balldream">Twitter</a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/balldreamcom">Pinterest</a>
                    </li>
                    <li>
                    <a href="https://www.youtube.com/channel/UCB2FGaVCyf_2m1tHhpr4mpg">Youtube</a>
                    </li>
                  </ul>
                </div>

              </div>

            </div>

          </div>

        </div>

      </footer>
    )
  }
}

export default Footer;